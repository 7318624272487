import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button'
import './BackBtn.css';

function BackBtn({ label, customClickEvent }) {
  return (
    <div className="backBtn-box" onClick={customClickEvent}>
      <Button className="back-btn" variant="light" size="lg">{label}</Button>
    </div>
  );
}

export default BackBtn;

BackBtn.propTypes = {
  label: PropTypes.string,
  customClickEvent: PropTypes.func,
};