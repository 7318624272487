import Modal from 'react-bootstrap/Modal';
import './InfoPopup.css';
import React from "react";

function InfoPopup(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="infoPopup">
                <h2>Feature available in Version II</h2>
            </Modal.Body>
        </Modal>
    );
  }
  
  export default InfoPopup;