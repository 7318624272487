import { combineReducers } from "redux";
import countReducer from "./countReducer";
import imageBtnOptions from "./imageBtnOptions";
import imageOptionsReducer from "./imageOptionsReducer"
import imageDataReducer from "./imageDataReducer";

const rootReducer = combineReducers({
    countReducer,
    imageBtnOptions,
    imageOptionsReducer,
    imageDataReducer,
});
export default rootReducer;