import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './Loader.css';

function Loader({ show }) {
    return (<Fragment>
        {show &&
            <div className="page-loader">
                <div className="loader-small"></div>
            </div>
        }
    </Fragment>
    );
}

export default Loader;

Loader.propTypes = {
  show: PropTypes.boolean,
};