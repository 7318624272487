import { useHistory } from "react-router-dom";
import { setCreateProjectData } from './../../actions/imageData';
import { useDispatch } from 'react-redux';
import React, { useState } from "react";
import { setImageUrls, setCameraImageUrls, setHomography, addFloorPlanTestPoint, setFloorPlanTestPoint,addCameraTestPoint, setCameraTestPoint} from './../../actions/imageData';
import sync from "./../../assets/icons/sync.webp";
import compliant from "./../../assets/icons/compliant.webp";
import './Home.css';

function Home() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    client_name: null,
    building_name: null,
    project_name: null
  });

  const handleLoadProject = () => {
    dispatch(setCreateProjectData(values));
    dispatch(setImageUrls([]));
    dispatch(setCameraImageUrls([]));
    dispatch(setHomography(null));
    dispatch(addFloorPlanTestPoint(null));
    dispatch(setFloorPlanTestPoint(null));
    dispatch(addCameraTestPoint(null));
    dispatch(setCameraTestPoint(null));
    history.push("/loadFloorPlan");
  }
  const handleCreateProject = () => {
    dispatch(setCreateProjectData(values));
    dispatch(setImageUrls([]));
    dispatch(setCameraImageUrls([]));
    dispatch(setHomography(null));
    dispatch(addFloorPlanTestPoint(null));
    dispatch(setFloorPlanTestPoint(null));
    dispatch(addCameraTestPoint(null));
    dispatch(setCameraTestPoint(null));
    history.push("/createProject");
  }
  return (
    <div className="Home">
      <div className="intro-box">
        <h2>Welcome to the Pathr.ai™ Calibration Tool</h2>
        <p>Load or create a project and plot corresponding points in two views (typically a floor plan and a camera view)</p>
        <div className="inner-intro-box">
          <p>Plotting point within your scene will allow for spatial rectification, registration, and computational of camera motion-rotation and translation-between the two views. These calibration points will be used to generate a homography matrix which will be used to plot these points into an image, so that they are rendered with the correct perspective and appear to have been part of the original scene.
          </p>
          <div className="button-container">
            <div className="home-green-btn mr-70" onClick={handleLoadProject}>
              <div>
                <img src={sync} alt="loadIcon" className="sync" />
                <span className="heading-in-btn">
                  LOAD PROJECT
                </span>
              </div>
              <div className="sub-heading-in-btn">Load and edit existing project (.json file from local directoy)</div>
            </div>
            <div className="home-green-btn" onClick={handleCreateProject}>
              <div>
                <img src={compliant} alt="createIcon" className="compliant" />
                <span className="heading-in-btn">
                  CREATE PROJECT
                </span>
              </div>
              <div className="sub-heading-in-btn">Create a new project from the ground up</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;