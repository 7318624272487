import Modal from 'react-bootstrap/Modal';
import './CustomMessagePopup.css';
import React from "react";

function CustomMessagePopup(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="CustomMessagePopup">
                <h2>{props.label}</h2>
            </Modal.Body>
        </Modal>
    );
  }
  
  export default CustomMessagePopup;