import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import AlertIcon from './../../../assets/icons/icon-alert.svg';
import './SaveWithoutHomography.css';
import { showSaveProject } from './../../../actions/imageOptions';
import React from "react";

function SaveWithoutHomography(props) {
    const dispatch = useDispatch();

    const handleYes = () => {
        props.onHide();
        dispatch(showSaveProject());
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="saveWithoutHomography">
                <img src={AlertIcon} alt="alert-icon" />
                <p>Homography is not generated, do you want to Save project?</p>
                <div className="BTN-blue-overlay" onClick={handleYes}>
                    Yes
                </div>
                <div className="BTN-blue-overlay" onClick={props.onHide}>
                    No
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SaveWithoutHomography;