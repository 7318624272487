import GreenBtnLg from '../helpers/greenBtnLg/GreenBtnLg';
import BackBtn from './../helpers/backBtn/BackBtn';
import CustomImageUploader from "../CreateProject/CustomImageUploaderComponent";
import { Link } from "react-router-dom";
import { showImageButtonOptions } from './../../actions/index';
import { showSceneLibrary, setAddSceneMultipleFlag } from './../../actions/imageOptions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './AddScene.css';
import { setImageUrls, setCameraImageUrls } from './../../actions/imageData';
import CloseIcon from "./../../assets/icons/cancel.webp";

function AddScene() {
  const dispatch = useDispatch();
  const history = useHistory();
  const cameraImageUrls = useSelector(state => state.imageDataReducer.cameraImageUrls);
  const sceneMultipleFlag = useSelector(state => state.imageOptionsReducer.sceneMultipleFlag); 

  const [errorMessage, setErrorMessage] = useState(null);
  const [maxImageError, setMaxImageError] = useState(false);
  const [singleImageError, setSingleImageError] = useState(false);
  const [multipleImageFlag, setMultipleImageFlag] = useState(false);
  const [invalidImageError, setInvalidImageError] = useState(false);

  const [values, setJsonValues] = useState({
    cameraImg: cameraImageUrls
  });

  function useForceUpdate(){    
    return () => setMultipleImageFlag(multipleImageFlag => true);
 // update the state to force render
}

  const handleBack = () => {
    history.push("/createProject");
  }

  const onSubmit = () => {
    if (!cameraImageUrls.length) {
      setErrorMessage("Please upload the camera view image.")
    } else {
      setErrorMessage("");
      history.push("/imageMapping");
      dispatch(showImageButtonOptions());
      dispatch(showSceneLibrary());
    }
  }
  const addSceneMultipleFlag = (flag) => {
    dispatch(setAddSceneMultipleFlag(flag));
  }

  const removeOldImage = () => {
    setJsonValues({
      ...values,
      cameraImg:[]
    });
  }

  const handleDeleteImage = (e) => {
    let index = e.target.getAttribute("data");
    let cameraImageUrlsTemp = cameraImageUrls;
    cameraImageUrlsTemp.splice(index, 1);
    setJsonValues({
      ...values,
      cameraImg:cameraImageUrlsTemp
    });
    if(cameraImageUrlsTemp.length < 60){
      setMaxImageError(false);
    }
    dispatch(setCameraImageUrls(cameraImageUrlsTemp));
  }
  // call your hook here
 useForceUpdate();
  return (
    <div className="AddScene">
      <div className="title">CREATE PROJECT</div>
      <div className="container">
        <h2 className="heading">Add Scene</h2>
        <p className="description">
          A Scene consistes of two spaces, the floor plan and a still from a video camera.<br />
          Scenes will be titled using the filename of the camera image you upload. You must<br />
          add at least one scene to create a project.<br />
          (.png .jpg .jpeg)
        </p>
        <div className="btn-list">
          <CustomImageUploader
            buttonText="UPLOAD SINGLE IMAGE"
            singleImage={true}
            singleCameraImage={true}
            setMaxImageError={setMaxImageError}
            setSingleImageError={setSingleImageError}
            setErrorMessage={setErrorMessage}
            setMultipleImageFlag = {setMultipleImageFlag}
            withPreview = {false}
            removeOldImage = {removeOldImage}
            setInvalidImageError={setInvalidImageError}
            cameraImageUrls = {cameraImageUrls}
            setAddSceneMultipleFlag = {addSceneMultipleFlag}
            sceneMultipleFlag = {sceneMultipleFlag}
          />
          <CustomImageUploader
            buttonText="UPLOAD BULK IMAGE"
            singleImage={false}
            singleCameraImage={false}
            setMaxImageError={setMaxImageError}
            setErrorMessage={setErrorMessage}
            setMultipleImageFlag = {setMultipleImageFlag}
            withPreview = {false}
            removeOldImage = {removeOldImage}
            setInvalidImageError={setInvalidImageError}
            cameraImageUrls = {cameraImageUrls}
            setAddSceneMultipleFlag = {addSceneMultipleFlag}
            sceneMultipleFlag = {sceneMultipleFlag}
          />
        </div>

        {((cameraImageUrls.length > 0 && values.cameraImg.length > 0) || multipleImageFlag) ?
          <div className="fileUploader ">
            <div className="fileContainer">
              <div className="uploadPicturesWrapper">
                <div className="uploadPicturesWrapper_inner_div">
                  {cameraImageUrls.length > 10 ?
                      cameraImageUrls.map((object,i) =>{
                        let name = object.split(";")[1].slice(5);
                        let temp = name.split(".")
                        name = temp[0].slice(0,8)+"."+temp[1];
                        return(
                        <div className="image-pill">
                          <div className="image-pill-text">{name}</div>
                          <img src={CloseIcon} alt="close-Icon" data={i} onClick={handleDeleteImage} />
                        </div>)
                      })
                      :
                      cameraImageUrls.map((object, i) =>
                        <div className="uploadPictureContainer">
                          <div className="deleteImage" data={i} onClick={handleDeleteImage}>X</div>
                          <img src={object} className="uploadPicture" alt="preview" />
                        </div>
                      )}
                </div>
              </div>
            </div>
          </div>
          : ""
        }
        {errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {maxImageError && <p className="errorMessage">Maximum limit of 60 images is reached for bulk upload. Try in next batch upload.</p>}
        {singleImageError && <p className="errorMessage">Invalid Request. Remove the old image and then try to add new image.</p>}
        {invalidImageError && <p className="errorMessage">Please upload the valid image. (.png .jpg .jpeg) images only allowed.</p>}
        <div className="navigation-buttons" >
          <BackBtn label="BACK" customClickEvent={handleBack} />
          <GreenBtnLg label="FINISH" customClickEvent={onSubmit} />
        </div>
      </div>
    </div>
  );
}

export default AddScene;