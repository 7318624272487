import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button'
import './GreenBtnLg.css';

function GreenBtnLg({ label, customClickEvent, disableFlag = false, title}) {
  return (
    <div className="GreenBtnLg" onClick={customClickEvent}>
      <Button className="green-btn" variant= {!disableFlag ? "primary" : ""} size="lg" disabled = {disableFlag} title={title}>{label}</Button>
    </div>
  );
}

export default GreenBtnLg;

GreenBtnLg.propTypes = {
  label: PropTypes.string,
  customClickEvent: PropTypes.func,
};