import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { resetState } from './../../../actions/index';
import './SaveProjectToHomePopup.css';
import React from "react";

function SaveProjectToHomePopup(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleYes = () => {
        props.onHide();
        dispatch(resetState());
        history.push("/");
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="saveProjectToHomePopup">
                <p>Your project detail is saved now. Do you want us <br/>to redirect you to main screen?</p>
                <div className="BTN-blue-overlay" onClick={handleYes}>
                    Yes
                </div>
                <div className="BTN-blue-overlay" onClick={props.onHide}>
                    No
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SaveProjectToHomePopup;