import {
    SET_INCREMENT,
    SET_DECREMENT,
    SHOW_IMAGE_BUTTON_OPTIONS,
    HIDE_IMAGE_BUTTON_OPTIONS,
    RESET_STATE,
} from './constants';

export const setIncrement = () => ({
    type: SET_INCREMENT,
});

export const setDecrement = () => ({
    type: SET_DECREMENT,
});

export const showImageButtonOptions = () => ({
    type: SHOW_IMAGE_BUTTON_OPTIONS,
});

export const hideImageButtonOptions = () => ({
    type: HIDE_IMAGE_BUTTON_OPTIONS,
});

export const resetState = () => ({
    type: RESET_STATE,
});