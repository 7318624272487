import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';
import GreenBtnLg from './../helpers/greenBtnLg/GreenBtnLg';
import './EditScene.css';
import React, { useState, useEffect } from "react";
import CustomImageUploader from "./../CreateProject/CustomImageUploaderComponent";
import { useSelector, useDispatch } from 'react-redux';
import { setHomography, addFloorPlanTestPoint, setFloorPlanTestPoint, addCameraTestPoint, setCameraTestPoint, setCameraImageUrls, setloadCameraUrl, setcurrentCameraUrl, setcurrentcameraNotes, setcurrentCameraImageIndex, setCameramarkers, setFloorplanmarkers, setMaskShapes} from './../../actions/imageData';
import {	hideEditScene } from './../../actions/imageOptions';

function EditScene(props) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [singleImageError, setSingleImageError] = useState(false);
    const cameraImageUrls = useSelector(state => state.imageDataReducer.cameraImageUrls);
    const getCurrentCameraUrl = useSelector(state => state.imageDataReducer.currentCameraUrl);
    let currentCameraIndex = useSelector(state => state.imageDataReducer.currentCameraIndex);
    let currentCameraNotes = useSelector(state => state.imageDataReducer.currentCameraNotes);
    const floorplanmarkers = useSelector(state => state.imageDataReducer.floorplanmarkers);
    const cameramarkers = useSelector(state => state.imageDataReducer.cameramarkers);
    let floorPlanAPITestPoints = useSelector(state => state.imageDataReducer.floorPlanAPITestPoints);
    let cameraAPITestPoints = useSelector(state => state.imageDataReducer.cameraAPITestPoints);
    let floorPlanTestPoints = useSelector(state => state.imageDataReducer.floorPlanTestPoints);
    let cameraTestPoints = useSelector(state => state.imageDataReducer.cameraTestPoints);
    let homography = useSelector(state => state.imageDataReducer.homography);
    const maskShapes = useSelector(state => state.imageDataReducer.maskShapes);

    let itemSplit = getCurrentCameraUrl ? getCurrentCameraUrl.split(";") : cameraImageUrls[0].split(";");
    const cameraName  = itemSplit[1].split("name=");
    const [editedImage, setEditedImageState] = useState(cameraImageUrls[currentCameraIndex]);
    const [imageFlag, setImageFlag] = useState(false);
    if(currentCameraIndex === undefined)
      currentCameraIndex = 0;

    let currentNote = "";
    if((currentCameraNotes && currentCameraNotes[currentCameraIndex] !== undefined))
      currentNote = currentCameraNotes[currentCameraIndex]
    function getInitialState(currentNote) {
      return currentNote;
    }
    let [cameraNotes, setTitle] = useState(getInitialState());
    const [invalidImageError, setInvalidImageError] = useState(false);
    const [deleteImageError, setDeleteImageError] = useState(false);
    
    const dispatch = useDispatch();

    function useForceUpdate(){            
      return () => setEditedImageState(cameraImageUrls[currentCameraIndex]);
   // update the state to force render
    }
      // call your hook here
    useForceUpdate();
    const handleDelete = () => {
			setInvalidImageError(false);

      if(cameraImageUrls.length >1){
        // Remove Notes
        if((currentCameraNotes && currentCameraNotes[currentCameraIndex] !== undefined)){
          currentCameraNotes.splice(currentCameraIndex, 1);
          dispatch(setcurrentcameraNotes(currentCameraNotes));
        }

        // Remove Scene
        cameraImageUrls.splice(currentCameraIndex, 1);

        // Remove Homography for the scene
        if(homography && homography[currentCameraIndex] && homography[currentCameraIndex] !== undefined){
          homography.splice(currentCameraIndex, 1);
          dispatch(setHomography(homography));
        }

        // Remove markers & TestMarkers for the scene
        if((cameraTestPoints && cameraTestPoints[currentCameraIndex] !== undefined)){
          cameraTestPoints.splice(currentCameraIndex, 1);
          dispatch(addCameraTestPoint(cameraTestPoints));
        }
        
        if((floorPlanAPITestPoints && floorPlanAPITestPoints[currentCameraIndex] !== undefined)){
          floorPlanAPITestPoints.splice(currentCameraIndex, 1);
          dispatch(setFloorPlanTestPoint(floorPlanAPITestPoints));
        }

        if((floorPlanTestPoints && floorPlanTestPoints[currentCameraIndex] !== undefined)){
          floorPlanTestPoints.splice(currentCameraIndex, 1);
          dispatch(addFloorPlanTestPoint(floorPlanTestPoints));
        }

        if((cameraAPITestPoints && cameraAPITestPoints[currentCameraIndex] !== undefined)){
          cameraAPITestPoints.splice(currentCameraIndex, 1);
          dispatch(setCameraTestPoint(cameraAPITestPoints));
        }

        if((floorplanmarkers && floorplanmarkers[currentCameraIndex] !== undefined)){
          floorplanmarkers.splice(currentCameraIndex, 1);
          dispatch(setFloorplanmarkers(floorplanmarkers));
        }
        
        if((cameramarkers && cameramarkers[currentCameraIndex] !== undefined)){
          cameramarkers.splice(currentCameraIndex, 1);
          dispatch(setCameramarkers(cameramarkers));
        }

        dispatch(setloadCameraUrl(true));
        dispatch(setCameraImageUrls(cameraImageUrls));

        let preImageIndex = currentCameraIndex-1;
        let postImageIndex = currentCameraIndex+1;

        if(cameraImageUrls[preImageIndex] !== undefined){
          dispatch(setcurrentCameraUrl(cameraImageUrls[currentCameraIndex]));
          dispatch(setcurrentCameraImageIndex(preImageIndex));
        }
        else if(cameraImageUrls[postImageIndex] !== undefined){
          dispatch(setcurrentCameraUrl(cameraImageUrls[postImageIndex]));
          dispatch(setcurrentCameraImageIndex(postImageIndex));
        }
        else if(cameraImageUrls[0] !== undefined){
          dispatch(setcurrentCameraUrl(cameraImageUrls[0]));
          dispatch(setcurrentCameraImageIndex(0));
        }
        else{

        }
      }
      else{
        setDeleteImageError(true);
        return;
      }
      dispatch(hideEditScene());
    }

    const handleUpdate = () => {
      if(!editedImage.length) 
      {
        setInvalidImageError(true);
        return;
      }
			setInvalidImageError(false);
          let duplicate = false;
          for(var i = 0; i< cameraImageUrls.length; i++){
            let itemSplit = cameraImageUrls[i] .split(";");
            const imgBase64  = itemSplit[2];
            let itemSpl = editedImage .split(";");
            const uniImgBase64  = itemSpl[2];
            if(uniImgBase64 === imgBase64) {
              duplicate = true;
            }
          }

          // Notes
          if(currentCameraNotes && currentCameraIndex !== undefined ){
            currentCameraNotes[currentCameraIndex] = cameraNotes;
            dispatch(setcurrentcameraNotes(currentCameraNotes));
          }
          else {
            let notes = [];
            notes[currentCameraIndex] = cameraNotes;
            dispatch(setcurrentcameraNotes(notes));
          }
          
          if(editedImage !== undefined && editedImage !== "" && !duplicate){
              dispatch(setcurrentCameraUrl(editedImage));
              cameraImageUrls[currentCameraIndex] = editedImage;
              dispatch(setloadCameraUrl(true));
              dispatch(setCameraImageUrls(cameraImageUrls));
              if((cameramarkers && cameramarkers[currentCameraIndex] !== undefined)){
                cameramarkers[currentCameraIndex] = "";
                dispatch(setCameramarkers(cameramarkers));
              }
              if((cameraTestPoints && cameraTestPoints[currentCameraIndex] !== undefined)){
                cameraTestPoints[currentCameraIndex] = "";
                dispatch(setCameraTestPoint(cameraTestPoints));
              }
              if((homography && homography[currentCameraIndex] !== undefined)){
                homography[currentCameraIndex] = "";
                dispatch(setHomography(homography));
              }
              if((maskShapes && maskShapes[currentCameraIndex] !== undefined)){
                maskShapes[currentCameraIndex] = "";
                dispatch(setMaskShapes(maskShapes));
                }                        
          }
          dispatch(hideEditScene());
    }

		const setEditedImage = (image) => {
      setEditedImageState(image);
		}

		const closeModal = () => {
			setInvalidImageError(false);
      props.onHide();
		}

    const handleOnload = () => {
      setTitle(currentNote);
    }

    const handleDeleteImage = (e) => {
      setEditedImageState([]);
      setImageFlag(false);
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
						onHide={closeModal}
            onShow={handleOnload}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                EDIT SCENE: {cameraName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="edit-scene-body">
                    <h2>Edit Scene Notes</h2>
                    <p>*Up to 100 characters. This will appear under the scene title.</p>
                       <Form.Control
                        className="text-area"
                        as="textarea"
                        placeholder="This Scene is very important to the Marketing Department. Map is really Tight!"
                        style={{ height: '100px' }}
                        maxlength="100"
                        onChange = {event => setTitle(event.target.value)}
                        value = {cameraNotes}
                     />

                    <h2>Update Camera View</h2>
                    <p>*Name of the scene will be updated based on the file name.</p>
                    <CustomImageUploader
                        buttonText="UPLOAD"
                        singleImage={true}
                        singleCameraImage={true}
                        setSingleImageError={setSingleImageError}
                        setErrorMessage={setErrorMessage}
                        withPreview = {true}
                        editScene = {true}
                        cameraImageUrls = {cameraImageUrls}
                        setEditedImage = {setEditedImage}
                        setInvalidImageError={setInvalidImageError}
                        setMultipleImageFlag = {setImageFlag}
                    />

                    {(editedImage.length > 0 && editedImage.length) || imageFlag ?
                      <div className="fileUploader ">
                        <div className="fileContainer">
                          <div className="uploadPicturesWrapper">
                            <div className="uploadPicturesWrapper_inner_div">
                              <div className="uploadPictureContainer">
                                <div className="deleteImage" onClick={handleDeleteImage}>X</div>
                                <img src={editedImage} className="uploadPicture" alt="preview" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      : ""
                    }
                    {invalidImageError && <p className="errorMessage">Please upload the valid image to update. (png, jpg, jpeg)</p>}
                    {deleteImageError && <p className="errorMessage">Unable to process delete scene operation. Atleast one scene is needed for homography!</p>}
                    <div className="btn-list">
                        <GreenBtnLg label="UPDATE"  customClickEvent={handleUpdate}/>
                        <GreenBtnLg label="DELETE SCENE" disableFlag = {(cameraImageUrls.length === 1 ? true : false)} customClickEvent={handleDelete} title = {"Unable to process delete scene operation. Atleast one scene is needed for homography!"}/>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
  }
  
  export default EditScene;