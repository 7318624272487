import { SHOW_IMAGE_BUTTON_OPTIONS, HIDE_IMAGE_BUTTON_OPTIONS, RESET_STATE} from './../actions/constants';

const initialstate=false;

function imageBtnOptions(state=initialstate,action){
    switch(action.type){
        case SHOW_IMAGE_BUTTON_OPTIONS:
            return true;
        case HIDE_IMAGE_BUTTON_OPTIONS:
            return false;
        case RESET_STATE:
            return false;
        default:
            return state;
    }
}

export default imageBtnOptions;